<template>
  <div>
    <div class="title">
      学校:{{data.school_name?data.school_name:"-"}}&nbsp;&nbsp;
      院系:{{data.college_name?data.college_name:"-" }}&nbsp;&nbsp;
      专业:{{data.major_name }}&nbsp;&nbsp;
      学年:{{data.school_year? data.school_year +"-" +(data.school_year - 0 + 1): "-"}}&nbsp;&nbsp;
      学期:{{data.semester==1 ? '第一学期' : "第二学期" }}&nbsp;&nbsp;
      年级:{{data.grade_name}}&nbsp;&nbsp;
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  name: "Title",
  props: {
    data: {
      type: Object,
      default: {},
    },
    isShow:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.title{
    // margin: 20px 0
    margin-bottom: 20px;
}
</style>