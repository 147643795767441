<template>
  <div class="app-container">
    <div class="box">
      <div class="top-name">课件上传须知:</div>
      <!-- <div class="centont-p">1、上传的课件必选小于20MB</div>
      <div class="centont-p">2、每次最多同时上传5份课件</div> -->
      <div class="centont-p">1、只支持以下格式的课件</div>
      <div class="tips">
        <img src="../assets/word.png" alt="" class="icon" />
        <p>doc,docx (MS Word文档)</p>
      </div>
      <div class="tips">
        <img src="../assets/xls.png" alt="" class="icon" />
        <p>xls,xlsx (MS Excel工作簿)</p>
      </div>
      <div class="tips">
        <img src="../assets/ppt.png" alt="" class="icon" />
        <p>ppt,pptx (ProwerPoint演示文稿)</p>
      </div>
      <div class="tips">
        <img src="../assets/pdf.png" alt="" class="icon" />
        <p>pdf (PDF文档)</p>
      </div>
      <div class="tips">
        <img src="../assets/mp3.png" alt="" class="icon" />
        <p>mp3 (Mp3音频)</p>
      </div>
      <div class="tips">
        <img src="../assets/zip.png" alt="" class="icon" />
        <p>zip,rar (压缩文档)</p>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.box {
  min-width: 190px;
  max-width: 350px;
  font-size: 14px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  border-radius: 16px;
  .centont-p {
    padding-left: 25px;
    margin: 10px 0px;
  }
  .tips {
    padding-left: 50px;
    display: flex;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
    }
    p {
      margin-left: 10px;
    }
  }
}
</style>