import Vue from "vue";
import Router from "vue-router";
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router);

/* Layout */
import Layout from "../views/layout/Layout";

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true
  },
  {
    path: "/error/401",
    component: () => import("@/views/error/401"),
    hidden: true
  },
  {
    path: "/error/404",
    component: () => import("@/views/error/404"),
    hidden: true
  },
  {
    path: "",
    component: Layout,
    name: "Index",
    hidden: true,
    children: [
      // {
      //   path: "/",
      //   name: "Dashboard",
      //   meta: { title: "首页", icon: "dashboard", affix: true },
      //   component: () => import("@/views/dashboard/index")
      // }
    ]
  },
  {
    path: "",
    component: Layout,
    name: "Info",
    hidden: true,
    children: [
      {
        path: "/info",
        component: () => import("@/views/info/index")
      }
    ]
  },
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path*",
        component: () => import("@/views/redirect/index")
      }
    ]
  }
];

//实例化vue的时候只挂载constantRouter
export default new Router({
  mode: "history",
  routes: constantRouterMap
});

//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "Teaching Task",
    meta: { title: "教学任务", permissions: ["admin"], icon: "users" },
    children: [
      {
        path: "/task/mycourse",
        name: "mycourse",
        component: () => import("@/views/task/mycourse"),
        meta: {keepAlive: true, title: "我的课程", permissions: ["admin"] }
      },
      {
        path: "/task/instructionaldesign",
        name: "instructionaldesign",
        hidden: true,
        component: () => import("@/views/task/instructionaldesign"),
        meta: { title: "教学设计", permissions: ["admin"] }
      },
      {
        path: "/task/coursewareinfo",
        name: "coursewareinfo",
        hidden: true,
        component: () => import("@/views/task/coursewareinfo"),
        meta: { title: "课件资料", permissions: ["admin"] }
      },
      {
        path: "/task/studentexam",
        name: "studentexam",
        hidden: true,
        component: () => import("@/views/task/studentexam"),
        meta: { title: "学生考试", permissions: ["admin"] }
      },
      {
        path: "/task/teachersummary",
        name: "teachersummary",
        hidden: true,
        component: () => import("@/views/task/teachersummary"),
        meta: { title: "教师总结", permissions: ["admin"] }
      },
      {
        path: "/task/studentscore",
        name: "studentscore",
        hidden: true,
        component: () => import("@/views/task/studentscore"),
        meta: { title: "学生成绩", permissions: ["admin"] }
      },
      {
        path: "/task/check_curriculum",
        name: "check_curriculum",
        hidden: true,
        component: () => import("@/views/task/check_curriculum"),
        meta: { title: "查看课表", permissions: ["admin"] }
      },
      {
        path: "/task/class_student",
        name: "class_student",
        hidden: true,
        component: () => import("@/views/task/class_student"),
        meta: { title: "班级学生", permissions: ["admin"] }
      },
      {
        path: "/task/my_class",
        name: "my_class",
        component: () => import("@/views/task/my_class"),
        meta: {keepAlive: true, title: "我的班级", permissions: ["admin"] }
      },
    ]
  },

  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "Class_Schedule",
    meta: { title: "课程表管理", permissions: ["admin"], icon: "users" },
    children: [
      {
        path: "/schedule/timetable",
        name: "timetable",
        component: () => import("@/views/schedule/timetable"),
        meta: { title: "课程表排课", permissions: ["admin"] }
      },
      {
        path: "/schedule/index",
        name: "schedule",
        component: () => import("@/views/schedule/index"),
        meta: {keepAlive: true, title: "我的排课记录", permissions: ["admin"] }
      },
      {
        path: "/schedule/operation",
        name: "operation",
        component: () => import("@/views/schedule/operation"),
        hidden: true,
        meta: { title: "作业管理", permissions: ["admin"] }
      },
      {
        path: "/schedule/operation_details",
        name: "operation_details",
        hidden: true,
        component: () => import("@/views/schedule/operation_details"),
        meta: { title: "作业详情", permissions: ["admin"] }
      },
      {
        path: "/schedule/student",
        name: "operation",
        component: () => import("@/views/schedule/student"),
        hidden: true,
        meta: { title: "查看学生", permissions: ["admin"] }
      },
      {
        path: "/schedule/mysetting",
        name: "mysetting",
        hidden: true,
        component: () => import("@/views/schedule/mysetting"),
        meta: { title: "我的排课设置", permissions: ["admin"] }
      },
      {
        path: "/schedule/batch",
        name: "batch",
        hidden: true,
        component: () => import("@/views/schedule/batch"),
        meta: { title: "批量设置排课", permissions: ["admin"] }
      },
      {
        path: "/schedule/addClassHours",
        name: "addClassHours",
        hidden: true,
        component: () => import("@/views/schedule/addClassHours"),
        meta: { title: "新增/编辑单个排班", permissions: ["admin"] }
      },
      
    ]
  },
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "question",
    meta: { title: "教师题库管理", permissions: ["admin"], icon: "users" },
    children: [
      {
        path: "/question/question",
        name: "question",
        component: () => import("@/views/question/question"),
        meta: { title: "教师答题答题", permissions: ["admin"] }
      },
      {
        path: "/question/subject",
        name: "subject",
        hidden: true,
        component: () => import("@/views/question/subject"),
        meta: { title: "随机答题", permissions: ["admin"] }
      },
      {
        path: "/question/record",
        name: "record",
        hidden: true,
        component: () => import("@/views/question/record"),
        meta: { title: "答题记录", permissions: ["admin"] }
      },
      {
        path: "/question/record_details",
        name: "record_details",
        hidden: true,
        component: () => import("@/views/question/record_details"),
        meta: { title: "答题详情", permissions: ["admin"] }
      },
      {
        path: "/question/student",
        name: "student",
        component: () => import("@/views/question/student"),
        meta: { title: "学生答题题库", permissions: ["admin"] }
      },
      {
        path: "/question/topic_edit",
        name: "topic_edit",
        hidden: true,
        component: () => import("@/views/question/topic_edit"),
        meta: { title: "题库编辑", permissions: ["admin"] }
      },
      {
        path: "/question/answer_record",
        name: "answer_record",
        hidden: true,
        component: () => import("@/views/question/answer_record"),
        meta: { title: "答题记录", permissions: ["admin"] }
      },
      {
        path: "/question/testpaper",
        name: "testpaper",
        component: () => import("@/views/question/testpaper"),
        meta: {keepAlive: true, title: "试卷管理", permissions: ["admin"] }
      },
      {
        path: "/question/topic",
        name: "topic",
        hidden: true,
        component: () => import("@/views/question/topic"),
        meta: { title: "题目管理", permissions: ["admin"] }
      },
      {
        path: "/question/topic_list",
        name: "topic_list",
        hidden: true,
        component: () => import("@/views/question/topic_list"),
        meta: { title: "添加题目", permissions: ["admin"] }
      },
      {
        path: "/question/score",
        name: "score",
        hidden: true,
        component: () => import("@/views/question/score"),
        meta: { title: "评分", permissions: ["admin"] }
      },
      {
        path: "/question/student_condition",
        name: "student_condition",
        hidden: true,
        component: () => import("@/views/question/student_condition"),
        meta: { title: "学生答题情况", permissions: ["admin"] }
      },
      {
        path: "/question/testpaper_edit",
        name: "testpaper_edit",
        hidden: true,
        component: () => import("@/views/question/testpaper_edit"),
        meta: { title: "试卷_编辑", permissions: ["admin"] }
      },
    ]
  },
  {
    path: "",
    component: Layout,
    alwaysShow: true,
    name: "studentManagement",
    meta: { title: "技能课程管理", permissions: ["eteacher"], icon: "example" },
    children: [
      {
        path: "/studentManagement/curriculumList",
        name: "curriculumList",
        component: () => import("@/views/studentManagement/curriculumList"),
        meta: { title: "课程列表", permissions: ["eteacher"] }
      },
      {
        path: "/studentManagement/lookApply",
        name: "lookApply",
        hidden: true,
        component: () => import("@/views/studentManagement/lookApply"),
        meta: { title: "查看学生", permissions: ["eteacher"] }
      },
      // {
      //   path: "/studentManagement/timetable",
      //   name: "timetable",
      //   component: () => import("@/views/studentManagement/timetable"),
      //   meta: { title: "我的技能课程表", permissions: ["eteacher"] }
      // }
    ]
  },
  { path: "*", redirect: "/error/404", hidden: true }
];
